<template>
  <div v-if="delivery">
    {{deliveryStatus}} ({{deliveryType}})
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  computed: {
    delivery() {
      return this.row.delivery
    },
    deliveryStatus() {
      return this.$t(`ec.data.delivery_status.${this.delivery.status}`)
    },
    deliveryType() {
      return this.$t(`ec.data.delivery_type.${this.delivery.type}`)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
